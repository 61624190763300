/**
 *  PlaidLink component
 */
import React, { useEffect, useState } from 'react';
import Button from '@src/components/Button';
import { usePlaidLink } from 'react-plaid-link';

import { createLinkToken, exchange } from '@src/api/plaid';
import PlusSVG from '@src/components/Icons/plus.svg';
import MoreSVG from '@src/components/Icons/more-horizontal.svg';

import './style.scss';

const PlaidLink = ({ accessToken }) => {
  const [token, setToken] = useState('');

  useEffect(() => {
    const fetchLinkToken = async () => {
      let options = accessToken ? { accessToken } : null;
      let {
        data: { link_token },
      } = await createLinkToken(options);
      setToken(link_token);
    };
    fetchLinkToken();
  }, []);

  /**
   *  Called after Plaid Link flow
   */
  const onSuccess = (publicToken, metadata) => {
    try {
      exchange({ publicToken });
    } catch (error) {
      console.warn(error);
    }
  };

  let { open, exit, ready } = usePlaidLink({ token, onSuccess });

  /**
   *  Opens Plaid Link interface
   */
  const triggerLink = () => {
    if (ready) {
      open();
    }
  };

  return (
    <Button className="plaid-link-button" onClick={triggerLink}>
      {!!accessToken ? <MoreSVG /> : <PlusSVG />}
      <span>
        {!!accessToken
          ? 'One bank account needs to be relinked!'
          : 'Add a Bank account'}
      </span>
    </Button>
  );
};

export default PlaidLink;
