/**
 *  Transactions container
 */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { debounce } from 'lodash';

import { useStores } from '@src/store';
import useKeyPress from '@src/utils/useKeyPress.hook';

import { formatMoney } from '@src/utils/format';
import FlyingMenu from '@src/components/FlyingMenu';
import ArchiveSVG from '@src/components/Icons/archive.svg';
import ReimbursedSVG from '@src/components/Icons/reimbursed.svg';
import ChevronSVG from '@src/components/Icons/chevron-down.svg';
import FolderSVG from '@src/components/Icons/folder.svg';
import SharedSVG from '@src/components/Icons/shared.svg';
import GoogleSVG from '@src/components/Icons/google.svg';
import Category from '@src/models/category';
import Project from '@src/models/project';

import './style.scss';

const Transactions = () => {
  const location = useLocation();

  const { TransactionsStore, UsersStore } = useStores();

  const searchInputRef = useRef(null);
  const transactionsUlRef = useRef(null);

  const [queryLimit, setQueryLimit] = useState(
    TransactionsStore.FIRESTORE_QUERY_LIMIT
  );
  const [focusedTransactions, setFocusedTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(-1);
  const [projectPopup, setProjectPopup] = useState(false);
  const [selectedProject, setSelectedProject] = useState('Inbox');
  const [transactionCategoryPopUp, setTransactionCategoryPopUp] =
    useState(null);
  const [transactionProjectPopUp, setTransactionProjectPopUp] = useState(null);

  const shortcutsDisabled = async () => {
    let isCommandPressed = await commandPressed;
    return (
      (transactionCategoryPopUp !== null && transactionCategoryPopUp >= -1) ||
      (transactionProjectPopUp !== null && transactionProjectPopUp >= -1) ||
      !!projectPopup ||
      !!isCommandPressed ||
      document.activeElement === searchInputRef.current
    );
  };

  const closeFlyingModals = () => {
    triggerTransactionCategoryPopup(null);
    triggerTransactionProjectPopup(null);
  };

  useEffect(() => {
    if (!location?.state) {
      return;
    }
    const filter = location?.state?.filter;
    const project = location?.state?.project;
    if (filter) {
      searchInputRef.current.value = filter;
      debouncedSearchHandler();
    }
    if (project) {
      setSelectedProject(project);
    }
  }, [location]);

  useEffect(() => {
    if (!selectedProject || selectedProject.includes('Search "')) {
      return;
    }
    let options = { queryLimit };
    if (selectedProject === 'Inbox') {
      options.type = 'inbox';
    } else if (selectedProject === 'Shared') {
      options.type = 'shared';
    } else if (selectedProject !== 'All transactions') {
      options.project = selectedProject;
    }
    TransactionsStore.fetch(options);
    setFocusedTransactions([]);
    setSelectedTransaction(-1);
    closeFlyingModals();
  }, [selectedProject, queryLimit]);

  useEffect(() => {
    if (focusedTransactions.length === 0 || !transactionsUlRef?.current) {
      return;
    }
    let focusedNode =
      transactionsUlRef.current.childNodes[
        focusedTransactions[focusedTransactions.length - 1]
      ];
    if (!focusedNode) {
      return;
    }
    let { bottom } = focusedNode.getBoundingClientRect();
    if (bottom > window.innerHeight + window.scrollY) {
      focusedNode.scrollIntoView({
        block: 'end',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [focusedTransactions]);

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((event) => {
        let searchQuery = searchInputRef.current.value;
        if (searchQuery !== null) {
          if (searchQuery === '') {
            TransactionsStore.fetch({ type: 'inbox' });
            setSelectedProject('Inbox');
          } else {
            TransactionsStore.search(searchQuery);
            setSelectedProject(`Search "${searchQuery}"`);
          }
          setFocusedTransactions([]);
          setSelectedTransaction(-1);
        }
      }, 500),
    []
  );

  const shiftPressed = useKeyPress(null, 'Shift');
  const commandPressed = useKeyPress(null, 'Meta');

  // Arrow Up
  useKeyPress(
    async (e) => {
      e.preventDefault();
      let multiSelect = await shiftPressed;
      setFocusedTransactions((prevFocuseds) => {
        if (prevFocuseds.length === 0) {
          return [0];
        }
        if (multiSelect) {
          if (prevFocuseds[prevFocuseds.length - 1] - 1 < 0) {
            return prevFocuseds;
          }
          if (prevFocuseds.length === 1) {
            prevFocuseds.push(prevFocuseds[prevFocuseds.length - 1] - 1);
          } else if (
            prevFocuseds[prevFocuseds.length - 2] >
            prevFocuseds[prevFocuseds.length - 1]
          ) {
            prevFocuseds.push(prevFocuseds[prevFocuseds.length - 1] - 1);
          } else {
            prevFocuseds.pop();
          }
          return prevFocuseds;
        }
        if (prevFocuseds[prevFocuseds.length - 1] - 1 < 0) {
          prevFocuseds = [TransactionsStore.getListLength() - 1];
        } else {
          prevFocuseds = [prevFocuseds[prevFocuseds.length - 1] - 1];
        }
        return prevFocuseds;
      });
      setSelectedTransaction(-1);
      closeFlyingModals();
    },
    'ArrowUp',
    shortcutsDisabled
  );

  // Arrow Down
  useKeyPress(
    async (e) => {
      e.preventDefault();
      let multiSelect = await shiftPressed;
      setFocusedTransactions((prevFocuseds) => {
        if (prevFocuseds.length === 0) {
          return [0];
        }
        if (multiSelect) {
          if (
            prevFocuseds[prevFocuseds.length - 1] + 1 >=
            TransactionsStore.getListLength()
          ) {
            return prevFocuseds;
          }
          if (
            prevFocuseds[prevFocuseds.length - 1] + 1 ===
            TransactionsStore.getListLength()
          ) {
            prevFocuseds.push(prevFocuseds[prevFocuseds.length - 1] + 1);
          } else if (
            prevFocuseds[prevFocuseds.length - 2] >
            prevFocuseds[prevFocuseds.length - 1]
          ) {
            prevFocuseds.pop();
          } else {
            prevFocuseds.push(prevFocuseds[prevFocuseds.length - 1] + 1);
          }
          return prevFocuseds;
        }
        if (
          prevFocuseds[prevFocuseds.length - 1] + 1 >=
          TransactionsStore.getListLength()
        ) {
          prevFocuseds = [0];
        } else {
          prevFocuseds = [prevFocuseds[prevFocuseds.length - 1] + 1];
        }
        return prevFocuseds;
      });

      setSelectedTransaction(-1);
      closeFlyingModals();
    },
    'ArrowDown',
    shortcutsDisabled
  );

  useKeyPress(() => {
    setFocusedTransactions([]);
    setSelectedTransaction(-1);
    closeFlyingModals();
  }, 'Escape');

  useKeyPress(
    (e) => {
      e.preventDefault();
      triggerSelectTransaction(focusedTransactions[0]);
    },
    ' ',
    shortcutsDisabled
  );
  useKeyPress(
    (e) => {
      e.preventDefault();
      searchInputRef?.current.focus();
    },
    '/',
    shortcutsDisabled
  );
  useKeyPress(
    () => {
      for (let i = 0; i < focusedTransactions.length; i++) {
        if (
          0 <= focusedTransactions[i] &&
          focusedTransactions[i] < TransactionsStore.getListLength()
        ) {
          let transaction =
            TransactionsStore.getOrderedList()[focusedTransactions[i]];
          archiveTransaction(transaction);
        }
      }
    },
    'e',
    shortcutsDisabled
  );
  useKeyPress(
    () => {
      for (let i = 0; i < focusedTransactions.length; i++) {
        if (
          0 <= focusedTransactions[i] &&
          focusedTransactions[i] < TransactionsStore.getListLength()
        ) {
          let transaction =
            TransactionsStore.getOrderedList()[focusedTransactions[i]];
          shareTransaction(transaction);
        }
      }
    },
    's',
    shortcutsDisabled
  );
  useKeyPress(
    (e) => {
      e.preventDefault();
      if (focusedTransactions.length === 1) {
        triggerTransactionCategoryPopup(focusedTransactions[0]);
      } else {
        triggerTransactionCategoryPopup(-1);
      }
    },
    'c',
    shortcutsDisabled
  );
  useKeyPress(
    (e) => {
      e.preventDefault();
      if (focusedTransactions.length === 1) {
        triggerTransactionProjectPopup(focusedTransactions[0]);
      } else {
        triggerTransactionProjectPopup(-1);
      }
    },
    'p',
    shortcutsDisabled
  );

  const triggerSelectTransaction = (i) => {
    if (selectedTransaction === i) {
      setSelectedTransaction(-1);
    } else {
      setFocusedTransactions([i]);
      setSelectedTransaction(i);
    }
  };

  /**
   *  Set Category Popup store if transaction is not pending
   */
  const triggerTransactionCategoryPopup = (idx) => {
    if (!!idx && 0 <= idx && idx < TransactionsStore.getListLength()) {
      let transaction = TransactionsStore.getOrderedList()[idx];
      if (!transaction.pending()) {
        setTransactionCategoryPopUp(idx);
      }
    } else {
      setTransactionCategoryPopUp(idx);
    }
  };

  /**
   *  Set Projects Popup store if transaction is not pending
   */
  const triggerTransactionProjectPopup = (idx) => {
    if (!!idx && 0 <= idx && idx < TransactionsStore.getListLength()) {
      let transaction = TransactionsStore.getOrderedList()[idx];
      setTransactionProjectPopUp(idx);
    } else {
      setTransactionProjectPopUp(idx);
    }
  };

  /**
   *  Archive a transaction in Firestore
   */
  const archiveTransaction = async (transaction) => {
    await transaction.archive();
    // Set focused & selected transaction to the previous one if archived transaction is the last item of
    if (
      focusedTransactions.length === 1 &&
      focusedTransactions[0] === TransactionsStore.getListLength()
    ) {
      setFocusedTransactions([TransactionsStore.getListLength() - 1]);
    } else if (selectedTransaction === TransactionsStore.getListLength()) {
      setSelectedTransaction(TransactionsStore.getListLength() - 1);
    } else {
      setFocusedTransactions([Math.min(...focusedTransactions)]);
    }
  };

  /**
   *  Share a transaction in Firestore
   */
  const shareTransaction = async (transaction) => {
    await transaction.share();
  };

  const updateCategory = async (transaction, category) => {
    !!transaction &&
      !transaction.pending() &&
      (await transaction.updateField('category', category.name));
  };

  const updateProject = async (transaction, project) => {
    if (!transaction) {
      return;
    }
    let projectName = project === 'None' ? null : project.name;
    await transaction.updateProject(projectName);
  };

  const googleSearchURL = (transaction) => {
    if (!transaction) {
      return null;
    }
    return `https://www.google.com/search?q=${transaction.name()} transaction`;
  };

  const renderSearchField = () => {
    const onKeyDown = (e) => {
      if (e.key === 'Escape' || e.key === 'Enter') {
        e.target.blur();
      }
    };
    return (
      <input
        type="text"
        placeholder="Search for a transaction... [/]"
        className="transactions--search big"
        onChange={debouncedSearchHandler}
        onKeyDown={onKeyDown}
        ref={searchInputRef}
      />
    );
  };

  const triggerExceptionalAt = (e, transaction) => {
    transaction.exceptional();
  };

  const triggerMagickedAt = (e, transaction) => {
    transaction.magic();
  };

  const triggerSkippedAt = (e, transaction) => {
    transaction.skip();
  };

  const renderActions = (transaction) => {
    const ownerImage = transaction.isFromSharedAccount()
      ? `data:image/jpeg;charset=utf-8;base64,${transaction.institution.logo}`
      : UsersStore.getUserByUid(transaction.userUid).photoURL;

    if (transaction.pending()) {
      return (
        <div className="transaction--block transaction--actions">
          {transaction.isFromSharedAccount() && transaction.shared() ? (
            <div className="transaction--action">
              <img referrerPolicy="no-referrer" src={ownerImage} />
            </div>
          ) : null}
        </div>
      );
    }

    if (
      !!transaction.visibleByBoth() &&
      !transaction.isCurrentUser() &&
      !transaction.shared()
    ) {
      let username = UsersStore.getUserByUid(transaction.userUid).shortName();
      return (
        <div className="transaction--block transaction--actions">
          <div className="transaction--action transaction--action-large non-selectable">
            Paid by {username}
          </div>
        </div>
      );
    }

    return (
      <div className="transaction--block transaction--actions">
        <div
          className={classnames({
            'transaction--action': true,
            shared: transaction.shared(),
            canHover: transaction.canShare(),
          })}
          title={`[s] ${transaction.shared() ? 'Unshare' : 'Share'}`}
          onClick={() => shareTransaction(transaction)}
        >
          {transaction.shared() ? (
            <img referrerPolicy="no-referrer" src={ownerImage} />
          ) : (
            <SharedSVG />
          )}
        </div>
        <div
          className={classnames({
            'transaction--action': true,
            archived: transaction.archived(),
            canHover: transaction.canArchive(),
          })}
          title={`[e] ${transaction.archived() ? 'Unarchive' : 'Archive'}`}
          onClick={() => archiveTransaction(transaction)}
        >
          <ArchiveSVG />
        </div>
      </div>
    );
  };

  /**
   *  Render transaction summary
   */
  const renderTransactionSummary = (transaction, i) => {
    return (
      <div className="transaction--summary">
        <div
          className="transaction--block transaction--emoji"
          title="[c] Change category"
        >
          <div onClick={() => triggerTransactionCategoryPopup(i)}>
            {transaction.emoji()}
          </div>
          {transactionCategoryPopUp === i && !transaction.pending() ? (
            <FlyingMenu
              onEnter={(res) => updateCategory(transaction, res)}
              onClose={() => triggerTransactionCategoryPopup(null)}
              elements={Category.getAll(transaction.project)}
              search
            />
          ) : null}
        </div>
        <div
          className="transaction--block transaction--name"
          onClick={() => triggerSelectTransaction(i)}
        >
          {transaction.reimbursed() ? <ReimbursedSVG /> : null}
          <div
            className={classnames('transaction--name-block', {
              striked: !!transaction.skippedAt,
              exceptional: transaction.isExceptional(),
              magic: transaction.isMagic(),
            })}
          >
            {!!transaction.isExceptional() ? '✨' : null}
            {!!transaction.isMagic() ? '🧞‍♂️' : null}
            {selectedTransaction === i
              ? transaction.name()
              : transaction.truncatedName()}
            {!!transaction.isMagic() ? '🧞‍♂️' : null}
            {!!transaction.isExceptional() ? '✨' : null}
          </div>
          <div className="transaction--pending">
            {transaction.pending() ? '(pending)' : ''}
          </div>
        </div>
        <div
          className={classnames({
            'transaction--block': true,
            'transaction--amount': true,
            striked: !!transaction.skippedAt,
            green: transaction.isAmountPositive(),
          })}
          onClick={() => triggerSelectTransaction(i)}
        >
          {transaction.amountComponent()}
        </div>
        <div
          className="transaction--block transaction--created-at"
          onClick={() => triggerSelectTransaction(i)}
        >
          {transaction.date()}
        </div>
        {!!transaction.pending() ? (
          <div className="transaction--block transaction--project"></div>
        ) : (
          <div
            className="transaction--block transaction--project"
            title="[p] Change project"
          >
            {transactionProjectPopUp === i ? (
              <div
                className={classnames({
                  'transaction--project-block': true,
                  'transaction--project-selected':
                    transactionProjectPopUp === i,
                })}
              >
                <FlyingMenu
                  onEnter={(res) => updateProject(transaction, res)}
                  onClose={() => triggerTransactionProjectPopup(null)}
                  elements={['None'].concat(Project.getAll())}
                  search
                />
                <span className="transaction--project-emoji">
                  {transaction.project?.emoji}
                </span>
                <span className="transaction--project-name">
                  {transaction.project?.name || 'Project'}
                </span>
              </div>
            ) : (
              <div
                className="transaction--project-block"
                onClick={() => triggerTransactionProjectPopup(i)}
              >
                <span className="transaction--project-emoji">
                  {transaction.project?.emoji}
                </span>
                <span className="transaction--project-name">
                  {transaction.project?.name}
                </span>
              </div>
            )}
          </div>
        )}

        {renderActions(transaction)}

        <div
          className={classnames({
            'transaction--block': true,
            'transaction--chevron': true,
            reverse: selectedTransaction === i,
          })}
          title={`[Space] ${selectedTransaction === i ? 'Close' : 'More info'}`}
          onClick={() => triggerSelectTransaction(i)}
        >
          <ChevronSVG />
        </div>
      </div>
    );
  };

  /**
   *  Render transaction details
   */
  const renderTransactionDetails = (transaction, i) => {
    return (
      <div className="transaction--detail">
        <ul>
          <li>
            <GoogleSVG />
            <a href={googleSearchURL(transaction)} target="_blank">
              <small>What's this?</small>
            </a>
          </li>
          {!transaction.pending() ? (
            // Exceptional expense
            <li>
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => triggerExceptionalAt(e, transaction)}
                  defaultChecked={transaction.isExceptional()}
                />
                <small>This is an exceptional transaction ✨</small>
              </label>
            </li>
          ) : null}
          {!transaction.pending() ? (
            // Magic expense
            <li>
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => triggerMagickedAt(e, transaction)}
                  defaultChecked={transaction.isMagic()}
                />
                <small>Carte magique 🧞‍♂️</small>
              </label>
            </li>
          ) : null}
          {!transaction.pending() ? (
            // Skip balance in stats
            <li>
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => triggerSkippedAt(e, transaction)}
                  defaultChecked={!!transaction.skippedAt}
                />
                <small>Skip this transaction in balances and stats</small>
              </label>
            </li>
          ) : null}
          <li>
            <a
              href={`https://console.firebase.google.com/u/0/project/ddbills-5ec60/firestore/data/~2FTransaction~2F${transaction.uid}`}
              target="_blank"
              className="very-small"
            >
              {transaction.uid}
            </a>
          </li>
        </ul>
        <ul>
          <li>{transaction.vendorName()}</li>
          <li>
            <img
              className="bank-logo"
              src={`data:image/jpeg;charset=utf-8;base64,${transaction.bankLogo()}`}
            />
            <div
              className="bank-name"
              style={{ color: transaction.bankColor() }}
            >
              {transaction.bankName()}
            </div>
            <span>{transaction.bankAccountName()}</span>
          </li>

          {!!transaction.archived() ? (
            <li className="transaction--detail-archived">
              <ArchiveSVG />
              Archived <span>{transaction.archivedAtHumanized()}</span>
            </li>
          ) : null}
          {!!transaction.shared() ? (
            <li className="transaction--detail-shared">
              <SharedSVG />
              Shared <span>{transaction.sharedAtHumanized()}</span>
            </li>
          ) : null}
          {!!transaction.reimbursed() ? (
            <li className="transaction--detail-reimbursed">
              <ReimbursedSVG />
              Reimbursed <span>{transaction.reimbursedAtHumanized()}</span>
            </li>
          ) : null}
        </ul>
      </div>
    );
  };

  const renderSortBy = () => {
    return (
      <div className="transaction--summary">
        <div className="transaction--block transaction--emoji"></div>
        <div className="transaction--block transaction--name"></div>
        <div
          className={classnames(
            'transaction--block',
            'transaction--amount',
            'transaction--sort',
            'non-selectable',
            { active: TransactionsStore.sortBy === 'amount' }
          )}
          onClick={() => {
            TransactionsStore.setSortBy('amount');
            TransactionsStore.triggerSortDirection();
          }}
        >
          Amount
          {TransactionsStore.sortBy !== 'amount'
            ? ' '
            : TransactionsStore.sortDirection === 'asc'
            ? ' ▴'
            : ' ▾'}
        </div>
        <div
          className={classnames(
            'transaction--block',
            'transaction--created-at',
            'transaction--sort',
            'non-selectable',
            { active: TransactionsStore.sortBy === 'date' }
          )}
          onClick={() => {
            TransactionsStore.setSortBy('date');
            TransactionsStore.triggerSortDirection();
          }}
        >
          Date
          {TransactionsStore.sortBy !== 'date'
            ? ' '
            : TransactionsStore.sortDirection === 'asc'
            ? ' ▴'
            : ' ▾'}
        </div>
        <div className="transaction--block transaction--project"></div>

        <div className="transaction--block transaction--actions"></div>

        <div className="transaction--block transaction--chevron"></div>
      </div>
    );
  };

  /**
   *  Render one transaction line
   */
  const renderTransaction = (transaction, i) => {
    return (
      <div
        className={classnames('transaction', {
          pending: !!transaction.pending(),
          archived: !!transaction.archived(),
          shared: !!transaction.shared(),
          focused: focusedTransactions.includes(i),
          selected: selectedTransaction === i,
        })}
        key={transaction.uid}
      >
        {renderTransactionSummary(transaction, i)}
        {renderTransactionDetails(transaction, i)}
      </div>
    );
  };

  /**
   *  Render list of all transactions to display
   */
  const renderTransactionsTable = () => {
    if (!!TransactionsStore.loading) {
      return (
        <div className="transaction">
          <div className="transaction--summary">
            <div className="transaction--block transaction--emoji">⌛️</div>
            <div className="transaction--block transaction--name">
              Loading...
            </div>
          </div>
        </div>
      );
    }

    let list = TransactionsStore.getOrderedList();
    if (list.length === 0) {
      return (
        <div className="transaction">
          <div className="transaction--summary">
            <div className="transaction--block transaction--emoji">🤷</div>
            <div className="transaction--block transaction--name">
              No transaction to display
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="transactions--list" ref={transactionsUlRef}>
        {renderSortBy()}
        {list.map(renderTransaction)}

        {!selectedProject.includes('Search "') || true ? (
          <div
            className="df-c small transactions--load-more"
            onClick={() => {
              setQueryLimit(
                queryLimit + TransactionsStore.FIRESTORE_QUERY_LIMIT
              );
            }}
          >
            Load more...
          </div>
        ) : null}
      </div>
    );
  };

  const getProjects = () => {
    let projects = ['Inbox', 'Shared', 'All transactions'];
    Project.getAll().forEach((p) => projects.push(p));
    return projects;
  };

  const getListInfo = () => {
    let list = TransactionsStore.getOrderedList();

    let count = list?.length || 0;

    let total = 0;
    list?.map((t) => (total += t.amount() || 0));
    total = formatMoney(total, 0, 'USD');

    return { count, total };
  };

  return (
    <div className="app-page transactions">
      <div className="ps">
        <div className="df-sb">
          <h1>
            {!!selectedProject ? selectedProject : 'Transactions'}
            <div className="subtitle df-aie">
              {TransactionsStore.loading ? (
                <div className="loading"></div>
              ) : (
                <div>
                  That's <strong>{getListInfo().count}</strong> transactions,
                  for a total of <strong>{getListInfo().total}</strong>.
                </div>
              )}
            </div>
          </h1>
          <div className="filter">
            <div onClick={() => setProjectPopup(true)}>
              <FolderSVG />
            </div>
            {!!projectPopup ? (
              <FlyingMenu
                onEnter={(res) => setSelectedProject(res.name || res)}
                onClose={() => setProjectPopup(false)}
                elements={getProjects()}
                className="right"
                search
              />
            ) : null}
          </div>
        </div>
        <hr />

        {renderSearchField()}
      </div>
      {renderTransactionsTable()}
      {transactionCategoryPopUp === -1 ? (
        <FlyingMenu
          onEnter={(res) => {
            focusedTransactions.forEach((i) =>
              updateCategory(TransactionsStore.getOrderedList()[i], res)
            );
          }}
          onClose={() => triggerTransactionCategoryPopup(null)}
          elements={Category.getAll()}
          title={`Updating ${focusedTransactions.length} transactions's category`}
          search
          className="full-screen"
        />
      ) : null}
      {transactionProjectPopUp === -1 ? (
        <FlyingMenu
          onEnter={(res) => {
            focusedTransactions.forEach((i) =>
              updateProject(TransactionsStore.getOrderedList()[i], res)
            );
          }}
          onClose={() => triggerTransactionProjectPopup(null)}
          elements={['None'].concat(Project.getAll())}
          title={`Updating ${focusedTransactions.length} transactions's project`}
          search
          className="full-screen"
        />
      ) : null}
    </div>
  );
};

export default observer(Transactions);
