/**
 *  Balance component
 */
import React from 'react';
import { Link } from 'react-router-dom';

import { useStores } from '../../../store';
import { formatMoney } from '../../../utils/format';

function Balance({ currentUserBalance, friendBalance, maxBalance }) {
  const { UsersStore } = useStores();

  const getHeight = (userBalance) => {
    if (userBalance == maxBalance && maxBalance == 0) {
      return 100;
    }
    return Math.max(Math.round((userBalance * 100) / maxBalance), 55);
  };

  return (
    <div className="statistic-block statistic-block-3">
      <div className="statistic-block-content">
        <div className="statistic-balance">
          <div
            className="statistic-graph statistic-graph--current-user"
            style={{
              height: `${getHeight(currentUserBalance)}%`,
            }}
          >
            <img
              src={UsersStore.currentUser.photoURL}
              referrerPolicy="no-referrer"
            />
            <div>{formatMoney(currentUserBalance, 0, 'USD')}</div>
          </div>
          <div
            className="statistic-graph statistic-graph--friend"
            style={{
              height: `${getHeight(friendBalance)}%`,
            }}
          >
            <img
              src={UsersStore.friend.photoURL}
              referrerPolicy="no-referrer"
            />
            <div>{formatMoney(friendBalance, 0, 'USD')}</div>
          </div>
        </div>
        <Link
          to="/"
          state={{ project: 'Shared' }}
          className="statistic-block--link"
        >
          See all shared transactions
        </Link>
      </div>
    </div>
  );
}

export default Balance;
