/**
 *  App container
 */
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStores } from '@src/store';

// Import Containers
import NavigationMenu from '@src/containers/NavigationMenu';

import './style.scss';

const AppRouter = () => {
  const { UsersStore } = useStores();

  useEffect(() => {
    UsersStore.listenStateChanged();
  }, [UsersStore]);

  const displayAppLoading = () => {
    return UsersStore.list.length == 0;
  };

  const renderAppContainer = () => {
    return (
      <div className="app-container">
        <NavigationMenu />
        <div className="middle-container">
          <Outlet />
        </div>
      </div>
    );
  };

  return (
    <div className="app">{displayAppLoading() ? '' : renderAppContainer()}</div>
  );
};

export default observer(AppRouter);
