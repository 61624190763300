/**
 *  Create React context and init hook useContext
 */
import React from 'react';
import { useLocalObservable } from 'mobx-react';

import BankAccountStatusStores from './bank_account_status';
import TransactionsStore from './transactions';
import UsersStore from './users';

const StoreContext = React.createContext();

export const StoreProvider = ({ children }) => {
  let usersStore = new UsersStore();
  const store = useLocalObservable(() => ({
    UsersStore: usersStore,
    TransactionsStore: new TransactionsStore(usersStore),
    BankAccountStatusStores: new BankAccountStatusStores(usersStore),
  }));
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStores = () => React.useContext(StoreContext);
