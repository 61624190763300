/**
 *  Transactions API
 */

import myAxios from './index';

export const indexTransaction = (uid) =>
  myAxios.get(`/transactions/${uid}/index`);

export const searchTransactions = (query) =>
  myAxios.get(`/transactions/search?query=${encodeURIComponent(query)}`, {
    query,
  });

export const getLastAppleCardImportDate = () => myAxios.get(`/transactions/apple/last-import-date`);

export const importAppleTransactions = (data, headers) =>
  myAxios.post(`/transactions/apple/import`, data, { headers });

export const reimburseTransactions = () =>
  myAxios.post(`/transactions/reimburse`);
