/**
 *  Button component
 */
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './style.scss';

const Button = ({ text, className, link, onClick, disabled, children }) => {
  const classNames = classnames('button', className, { disabled: !!disabled });

  if (!!link) {
    return (
      <Link to={link} className={classNames}>
        {text}
      </Link>
    );
  }
  const triggerOnClick = () => {
    if (typeof disabled !== 'undefined' && !!disabled) {
      return;
    }
    !!onClick && onClick();
  };
  return (
    <button onClick={triggerOnClick} className={classNames}>
      {text} {children}
    </button>
  );
};

export default Button;
