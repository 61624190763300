/**
 *  Accounts MobX Store
 */
import { makeAutoObservable, runInAction, reaction } from 'mobx';
import {
  getFirestore,
  query,
  collection,
  where,
  onSnapshot,
  orderBy,
  limit,
} from 'firebase/firestore';
import moment from 'moment';

const FIREBASE_COLLECTION_NAME = 'BankAccountStatus';
const FIRESTORE_QUERY_ORDER_BY = 'createdAt';
const FIRESTORE_QUERY_FROM_DATE = moment()
  .subtract(7, 'days')
  .startOf('month')
  .format('YYYY-MM-DD');

export default class BankAccountStatusStores {
  list = [];
  loading = false;

  constructor(usersStore) {
    makeAutoObservable(this);
    this.usersStore = usersStore;
  }

  fetch(options) {
    console.info('[Store][BankAccountStatusStores][fetch]');
    let { fromDate } = options || {};

    let db = getFirestore();
    let q = query(
      collection(db, FIREBASE_COLLECTION_NAME),
      where('userUid', '==', this.usersStore?.currentUser?.uid),
      where('deletedAt', '==', null),
      // where('type', '==', 'investment'),
      where('createdAt', '>=', fromDate || FIRESTORE_QUERY_FROM_DATE),
      orderBy(FIRESTORE_QUERY_ORDER_BY, 'asc')
    );
    onSnapshot(q, (account) => {
      runInAction(() => {
        this.loading = true;
        const list = [];
        account.forEach((d) => {
          list.push(d.data());
        });
        this.list = list;
        this.loading = false;
      });
    });
  }
}
