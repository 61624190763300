/**
 *  Settings container
 */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';

import { deletePlaidItem, fetchPlaidTransactions } from '@src/api/plaid';
import { importAppleTransactions } from '@src/api/transactions';
import { useStores } from '@src/store';
import Button from '@src/components/Button';
import PlaidLink from '@src/components/PlaidLink';
import TrashSVG from '@src/components/Icons/trash.svg';
import { formatMoney, formatDate } from '@src/utils/format';

import './style.scss';
import { getLastAppleCardImportDate } from '../../api/transactions';

const Settings = () => {
  const { UsersStore } = useStores();
  const [fetchingTransactions, setFetchingTransactions] = useState(false);
  const [appleCardTransaction, setAppleCardTransaction] = useState(null);
  const [importingCSV, setImportingCSV] = useState(false);
  const [plaidErrorAccessToken, setPlaidErrorAccessToken] = useState(null);

  useEffect(async () => {
    try {
      await UsersStore.currentUser?.fetchPlaidAccounts();
    } catch (error) {
      if (!!error?.response?.data?.errorCode) {
        setPlaidErrorAccessToken(error.response.data.accessToken);
      }
    }
    fetchLastAppleCardTransaction();
  }, [UsersStore.currentUser]);

  const fetchLastAppleCardTransaction = async () => {
    const {data: transaction} = await getLastAppleCardImportDate();
    setAppleCardTransaction(transaction);
  };

  const deleteAccount = async (account) => {
    let confirmed = window.confirm(
      'Are you sure you want to delete this account?'
    );
    if (!confirmed) {
      return;
    }
    await deletePlaidItem(account.accessToken);
  };

  const onInvestedAmountChanged = (accountId, newValue) => {
    UsersStore.currentUser.updateInvestedAmount(accountId, newValue);
  };

  const renderPlaidAccounts = (account) => {
    if (!!plaidErrorAccessToken) {
      return <PlaidLink accessToken={plaidErrorAccessToken} />;
    }
    if (UsersStore.currentUser?.plaidAccounts === null) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <ul className="settings--accounts">
          {!UsersStore.currentUser?.plaidAccounts?.length ? (
            <li>No account added yet.</li>
          ) : (
            UsersStore.currentUser?.plaidAccounts
              .slice()
              .sort((a1, a2) => {
                return a1.institution.name < a2.institution.name ? -1 : 1;
              })
              .map((account, i) => {
                return (
                  <li key={i}>
                    <div className="account-header">
                      <img
                        src={`data:image/jpeg;charset=utf-8;base64,${account.institution.logo}`}
                      />
                      <div
                        className="institution-name"
                        style={{ color: account.institution.primaryColor }}
                      >
                        {account.institution.name}
                        <small>
                          {!!account.institution.shared
                            ? ` — shared account with ${UsersStore.friend.shortNameOrYou()}`
                            : null}
                        </small>
                      </div>
                      {!account.institution.shared ? (
                        <div
                          className="account-trash"
                          onClick={() => deleteAccount(account)}
                        >
                          <TrashSVG />
                        </div>
                      ) : null}
                    </div>
                    <div className="account-list">
                      {account.accounts
                        .slice()
                        .sort((a1, a2) => (a1.subtype < a2.subtype ? -1 : 1))
                        .map((a) => {
                          return (
                            <div key={a.account_id} className="account">
                              <div>
                                <strong>{a.name || a.official_name}</strong>
                                <span className="account-subtype margin-left">
                                  ({a.subtype})
                                </span>
                              </div>
                              <div className="df-aic">
                                {a.subtype === 'brokerage' ? (
                                  <input
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    className="small"
                                    placeholder={a.balances.current}
                                    value={UsersStore.currentUser.getInvestedAmount(
                                      a.account_id
                                    )}
                                    onChange={(e) =>
                                      onInvestedAmountChanged(
                                        a.account_id,
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : null}
                                {formatMoney(
                                  a.balances.current,
                                  2,
                                  a.balances.iso_currency_code
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </li>
                );
              })
          )}
        </ul>
        <PlaidLink />
      </div>
    );
  };

  const triggerFetchPlaidTransactions = async () => {
    setFetchingTransactions(true);
    await fetchPlaidTransactions();
    setFetchingTransactions(false);
  };

  const renderAppleCardImport = () => {
    return (
      <div className="df-sb">
        <div>
          <p>
            Export monthly transactions from
            <a
              href="https://card.apple.com/"
              target="_blank"
              className="margin-left"
            >
              here
            </a>
            .
          </p>
          {appleCardTransaction && (
            <p>
              Last imported transaction was paid on{' '}
              <strong>
                {formatDate(appleCardTransaction.dateYearMonthDay, true)}
              </strong>.
            </p>
          )}
        </div>
        <div>
          <label htmlFor="apple-import-input" className="button">
            {importingCSV ? 'Importing...' : 'Import transactions'}
          </label>
          <input
            type="file"
            id="apple-import-input"
            name="apple-import-input"
            accept=".csv"
            disabled={!!importingCSV}
            onChange={uploadCSVAction}
          />
        </div>
      </div>
    );
  };

  const uploadCSVAction = async () => {
    setImportingCSV(true);
    let formData = new FormData();
    let inputFile = document.querySelector('#apple-import-input');
    formData.append('csvFile', inputFile.files[0]);
    await importAppleTransactions(formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    setImportingCSV(false);
  };

  return (
    <div className="app-page small settings">
      <h1>Settings</h1>
      <hr />

      <div className="settings--header df-sb">
        <div className="df-aic">
          <img
            src={UsersStore.currentUser.photoURL}
            referrerPolicy="no-referrer"
          />
          <div className="settings--header-info">
            <div className="settings--header-name">
              {UsersStore.currentUser.fullName()}
            </div>
            <div>{UsersStore.currentUser.email}</div>
          </div>
        </div>
        <div className="df-r">
          <NavLink className="button grey" to="/auth/logout">
            Logout
          </NavLink>
        </div>
      </div>

      <h2>Apple Card</h2>
      {renderAppleCardImport()}

      <h2 className="df-sb">
        Bank Accounts
        {/* Refresh transaction button hidden: */}
        {true ||
        !UsersStore.currentUser?.plaidAccounts ||
        UsersStore.currentUser?.plaidAccounts.length === 0 ? null : (
          <Button
            className="small"
            disabled={!!fetchingTransactions}
            onClick={triggerFetchPlaidTransactions}
          >
            <span>
              {!!fetchingTransactions ? 'Fetching...' : 'Refresh transactions'}
            </span>
          </Button>
        )}
      </h2>
      {renderPlaidAccounts()}
    </div>
  );
};

export default observer(Settings);
