/**
 *  Auth container
 */
import { Outlet } from 'react-router-dom';
import './style.scss';

const Auth = () => {
  return (
    <div className="auth">
      <Outlet />
    </div>
  );
};

export default Auth;
