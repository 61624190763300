/**
 *  Category Model
 */
import { makeAutoObservable } from 'mobx';

import Project from '@src/models/project';

export default class Category {
  name;

  static LIST = {
    'Bank Fees': {
      emoji: '🏧',
      color: '#cddafd',
    },
    Community: {
      emoji: '🌱',
      color: '#e2ece9',
    },
    'Food and Drink': {
      emoji: '🍕',
      color: '#ffd9b8',
    },
    Gift: {
      emoji: '🎁',
      color: '#ffd7ba',
    },
    Interest: {
      emoji: '💲',
      color: '#eae4e9',
    },
    Payment: {
      emoji: '💰',
      color: '#f8efe7',
    },
    House: {
      emoji: '🏠',
      color: '#f8efe7',
    },
    Recreation: {
      emoji: '🍿',
      color: '#fad2e1',
    },
    Savings: {
      emoji: '💰',
      color: '#fff1e6',
    },
    Service: {
      emoji: '👩‍💼',
      color: '#eae4e9',
    },
    Shops: {
      emoji: '🛍',
      color: '#fde2e4',
    },
    Tax: {
      emoji: '💸',
      color: '#fff1e6',
    },
    Transfer: {
      emoji: '💳',
      color: '#fff1e6',
    },
    Travel: {
      emoji: '🛫',
      color: '#dfe7fd',
    },
  };

  constructor(name) {
    makeAutoObservable(this);
    this.name = name || null;
  }

  color() {
    let list = {
      ...Category.LIST,
      ...Project.getSubCategories(),
    };
    return !!list[this.name] && list[this.name].color
      ? list[this.name].color
      : '#a2aab3';
  }

  emoji() {
    let list = {
      ...Category.LIST,
      ...Project.getSubCategories(),
    };
    return !!list[this.name] ? list[this.name].emoji : '🚫';
  }

  toString() {
    return `${this.emoji()} ${this.name}`;
  }

  static getAll(project) {
    let categoryList = Object.keys(Category.LIST).map((c) => new this(c));
    if (project && project.subcategories) {
      let subcategoryList = Object.keys(project.subcategories).map(
        (c) => new this(c)
      );
      categoryList = subcategoryList.concat(categoryList);
    }
    return categoryList;
  }

  static getAllAndSubcat() {
    let categoryList = Object.keys(Category.LIST).map((c) => new this(c));
    let projectsSubcats = Object.keys(Project.getSubCategories()).map(
      (c) => new this(c)
    );

    return categoryList.concat(projectsSubcats);
  }
}
