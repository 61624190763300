/**
 *  Statistics container
 */
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useStores } from '@src/store';

import Project from '@src/models/project';
import { formatDate } from '@src/utils/format';

import Balance from './components/balance';
import Reimburse from './components/reimburse';
import MonthStatistic from './components/monthStatistic';
import ProjectComponent from './components/project';

import './style.scss';

function Statistics() {
  const { TransactionsStore } = useStores();

  const TOP_X = 3;
  const [topX, setTopX] = useState(TOP_X);

  const NB_OF_MONTHS = 3;
  const months = Array.from(new Array(NB_OF_MONTHS)).map((a, i) => {
    return moment()
      .startOf('month')
      .subtract(i, 'month')
      .startOf('month')
      .format('YYYY-MM');
  });
  const [balanceStatistics, setBalanceStatistics] = useState(null);
  const [monthlyStatistics, setMonthlyStatistics] = useState({});
  const [lastReimbursedTransaction, setLastReimbursedTransaction] =
    useState(null);
  const [projectsStatistics, setProjectsStatistics] = useState({});

  useEffect(() => {
    fetchBalanceTransactions();
    fetchLastReimbursedTransaction();
    fetchThreeLastMonths();
    fetchAllProjects();
  }, []);

  const fetchBalanceTransactions = async () => {
    let transactions = await TransactionsStore.getTransactionsBy([
      ['reimbursedAt', '==', null],
      ['sharedAt', '!=', null],
    ]);
    setBalanceStatistics(transactions);
  };

  const fetchLastReimbursedTransaction = async () => {
    let transaction = await TransactionsStore.getLastReimbursed();
    setLastReimbursedTransaction(transaction);
  };

  const fetchThreeLastMonths = async () => {
    let fns = [];
    for (let dateYearMonth of months) {
      fns.push(
        new Promise(async (resolve) => {
          let transactions = await TransactionsStore.getTransactionsBy([
            ['dateYearMonth', '==', dateYearMonth],
          ]);
          monthlyStatistics[dateYearMonth] = transactions;
          setMonthlyStatistics({ ...monthlyStatistics });
          resolve();
        })
      );
    }
    Promise.all(fns);
  };

  const fetchAllProjects = async () => {
    let fns = [];
    for (let project of Project.getAllDisplayable()) {
      fns.push(
        new Promise(async (resolve) => {
          let transactions = await TransactionsStore.getTransactionsBy([
            ['project', '==', project.name],
          ]);
          let details = {
            name: project.name,
            exceptional: 0,
            magic: 0,
            total: 0,
            currentUser: 0,
            friend: 0,
            shared: 0,
            categories: {},
          };
          transactions.forEach((t) => {
            details.total += parseFloat(t.amount(), 10);
            if (t.isExceptional()) {
              details.exceptional += parseFloat(t.amount(), 10);
            } else if (t.isMagic()) {
              details.magic += parseFloat(t.amount(), 10);
            } else if (t.shared()) {
              details.shared += parseFloat(t.amount(), 10);
            } else {
              details.currentUser += parseFloat(
                t.amountPaidByCurrentUser(),
                10
              );
              details.friend += parseFloat(t.amountPaidByFriend(), 10);
            }

            details.categories[t.category] =
              (details.categories[t.category] || 0) +
              parseFloat(t.amount(), 10);
          });
          projectsStatistics[project] = details;
          setProjectsStatistics({ ...projectsStatistics });
          resolve();
        })
      );
    }
    Promise.all(fns);
  };

  const renderBalance = () => {
    let currentUserBalance = 0;
    let friendBalance = 0;
    balanceStatistics?.forEach((transaction) => {
      if (transaction.isFromSharedAccount()) {
        return;
      }
      if (transaction.isCurrentUser()) {
        currentUserBalance += transaction.amount();
      } else {
        friendBalance += transaction.amount();
      }
    });

    let costPerPerson = (currentUserBalance + friendBalance) / 2;
    let balanceTotal = costPerPerson - currentUserBalance;

    let maxBalance = Math.max(currentUserBalance, friendBalance);

    return (
      <div className="statistics-blocks">
        <Balance
          currentUserBalance={currentUserBalance}
          friendBalance={friendBalance}
          maxBalance={maxBalance}
        />
        <Reimburse balanceTotal={balanceTotal} />
      </div>
    );
  };

  const renderMonthsStatistics = () => {
    return months.map((a, i) => (
      <MonthStatistic
        monthlyStatistic={monthlyStatistics[months[i]]}
        dateYearMonth={months[i]}
        topX={topX}
        key={months[i]}
      />
    ));
  };

  const renderProjects = () => {
    return Object.keys(projectsStatistics).map((key) => (
      <ProjectComponent
        project={key}
        projectsStatistics={projectsStatistics}
        key={key}
      />
    ));
  };

  return (
    <div className="app-page statistics">
      <h1>Statistics</h1>
      <hr />

      <h2 className="df">
        Balance
        {!!lastReimbursedTransaction ? (
          <div>
            , since {formatDate(lastReimbursedTransaction?.reimbursedAt)}
          </div>
        ) : null}
      </h2>
      {renderBalance()}

      <h2 className="df-sb">
        Last three months
        {/* View/hide all */}
        <span
          className="show-more"
          onClick={() => (topX > TOP_X ? setTopX(TOP_X) : setTopX(1000))}
        >
          {topX > TOP_X
            ? `Show top ${TOP_X} categories only ▴`
            : 'Expand all categories ▾'}
        </span>
      </h2>
      <div className="statistics-blocks">{renderMonthsStatistics()}</div>

      <h2>Projects</h2>
      <div className="statistics-blocks">{renderProjects()}</div>
    </div>
  );
}

export default Statistics;
