/**
 *  useKeyPress hook
 */

import { useEffect, useState } from 'react';

export default async (cb, targetKey, disabledCb) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = async (event) => {
    if (!!disabledCb && (await disabledCb())) {
      return;
    }

    const { key } = event;
    if (key === targetKey) {
      setKeyPressed(true);
      !!cb && cb(event);
    }
  };

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);

  return keyPressed;
};
