/**
 *  Project Model
 */
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

export default class Project {
  name;
  emoji;
  expirationDate;
  subcategories;

  static LIST = [
    {
      name: 'Regroop',
      emoji: '✊',
    },
    {
      name: 'Revonah',
      emoji: '🏡',
      visibleByBoth: true,
      subcategories: {
        Furniture: {
          emoji: '🪑',
          color: '#cb997e',
        },
        Utilities: {
          emoji: '🧾',
          color: '#f0efeb',
        },
        Kitchen: {
          emoji: '🔪',
          color: '#eae4e9',
        },
      },
    },
    {
      name: 'Appart Barbès',
      emoji: '🏯',
      visibleByBoth: true,
    },
    {
      name: 'Wedding NYC',
      emoji: '💍',
      visibleByBoth: true,
      expirationDate: '2020-12-31',
    },
    {
      name: 'JC+Kro NYC 2023',
      emoji: '🐥',
      visibleByBoth: false,
      expirationDate: '2023-04-30',
    },
    {
      name: 'Wedding Castres',
      emoji: '💍',
      visibleByBoth: true,
      expirationDate: '2022-10-01',
    },
    {
      name: 'Car',
      emoji: '🚗',
      visibleByBoth: true,
      subcategories: {
        Gas: {
          emoji: '⛽️',
          color: '#fec5bb',
        },
        Parking: {
          emoji: '🅿️',
          color: '#cddafd',
        },
        Tolls: {
          emoji: '🛣',
          color: '#d8e2dc',
        },
      },
    },
    {
      name: 'Baby',
      emoji: '🥚',
      visibleByBoth: true,
      subcategories: {
        Healthcare: {
          emoji: '🧑‍⚕️',
          color: '#dfe7fd',
        },
        School: {
          emoji: '🧑‍🏫',
          color: '#e7dffd',
        },
      },
    },
    {
      name: 'Mexique - Feb 2022',
      emoji: '🇲🇽',
      expirationDate: '2022-03-30',
      visibleByBoth: true,
    },
    {
      name: 'France - Noël 2021',
      emoji: '🎄',
      expirationDate: '2022-01-30',
    },
    {
      name: 'Hélène & Max',
      emoji: '🗽',
      expirationDate: '2022-01-01',
      display: false,
    },
    {
      name: 'France - Sept 2021',
      emoji: '🇫🇷',
      expirationDate: '2021-10-01',
      display: false,
    },
    {
      name: 'France - August 2021',
      emoji: '🇫🇷',
      expirationDate: '2021-09-21',
      display: false,
    },
    {
      name: 'LA - August 2021',
      emoji: '🌅',
      expirationDate: '2021-08-29',
      display: false,
    },
    {
      name: 'Revonahtion #1',
      emoji: '🌳',
      expirationDate: '2021-07-20',
      display: false,
    },
    {
      name: 'Revonahtion #2',
      emoji: '🌳',
      expirationDate: '2022-06-01',
      display: false,
      visibleByBoth: true,
    },
    {
      name: 'Mexique - August 2021',
      emoji: '🌴',
      expirationDate: '2021-06-30',
      display: false,
    },
    {
      name: 'PapaMamanNYC',
      emoji: '🗽',
      expirationDate: '2022-06-30',
      display: false,
    },
    {
      name: 'France - May 2021',
      emoji: '🇫🇷',
      expirationDate: '2021-06-15',
      display: false,
    },
    {
      name: 'Miami - March 2021',
      emoji: '⛵️',
      expirationDate: '2021-05-01',
      display: false,
    },
    {
      name: 'Sedona - Feb 2021',
      emoji: '🏜',
      expirationDate: '2021-03-26',
      subcategories: {
        Hotel: {
          emoji: '🏨',
          color: '#fad2e1',
        },
      },
      display: false,
    },
    {
      name: 'Roadtrip - Nov 2020',
      emoji: '🌄',
      expirationDate: '2020-11-30',
      display: false,
    },
    {
      name: 'Yellowstone - Aug 2020',
      emoji: '⛰',
      expirationDate: '2020-09-01',
      display: false,
    },
    {
      name: 'Naples - July 2020',
      emoji: '🐊',
      expirationDate: '2020-08-01',
      display: false,
    },
  ];

  constructor(name) {
    makeAutoObservable(this);
    let project = Project.LIST.find((p) => p.name === name);
    this.name = project?.name || name;
    this.emoji = project?.emoji || null;
    this.expirationDate = project?.expirationDate || null;
    this.subcategories = project?.subcategories || null;
    this.display = project?.display;
    this.visibleByBoth = project?.visibleByBoth || false;
  }

  toString() {
    if (!this.name) {
      return null;
    }
    return `${this.emoji} ${this.name}`;
  }

  static getAll() {
    return Project.LIST.map((p) => new this(p.name));
  }

  static getAllValid() {
    return Project.getAll().filter(
      (p) => moment(p.expirationDate).endOf('day') >= moment()
    );
  }
  static getAllDisplayable() {
    return Project.getAll().filter((p) => p.display != false);
  }

  static getSubCategories() {
    let subcategories = {};
    Project.LIST.map((p) => {
      subcategories = {
        ...subcategories,
        ...p.subcategories,
      };
    });
    return subcategories;
  }
}
