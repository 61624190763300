/**
 *  Root container
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { StoreProvider } from '@src/store';
import firebase from '@src/utils/firebase';

import Auth from '@src/containers/Auth';
import Login from '@src/containers/Auth/Login';
import Logout from '@src/containers/Auth/Logout';
import App from '@src/containers/App';
import Accounts from '@src/containers/Accounts';
import Transactions from '@src/containers/Transactions';
import Statistics from '@src/containers/Statistics';
import Settings from '@src/containers/Settings';

import './style.scss';

const RootRouter = () => {
  return (
    <StoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path="auth" element={<Auth />}>
            <Route index element={<Login />} />
            <Route path="logout" element={<Logout />} />
          </Route>
          <Route path="/" element={<App />}>
            <Route index element={<Transactions />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="accounts" element={<Accounts />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </StoreProvider>
  );
};

ReactDOM.render(<RootRouter />, document.getElementById('root'));
export default RootRouter;
