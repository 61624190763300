/**
 *  Reimburse component
 */
import React, { useState } from 'react';

import { useStores } from '../../../store';
import { reimburseTransactions } from '../../../api/transactions';
import Button from '../../../components/Button';
import ReimbursedSVG from '../../../components/Icons/reimbursed.svg';
import { formatMoney } from '../../../utils/format';

function Reimburse({ balanceTotal }) {
  const { UsersStore } = useStores();

  const [reimburseLabel, setReimburseLabel] = useState('Mark as reimbursed');

  const reimburse = async () => {
    let confirm = window.confirm('Are you sure?');
    if (!!reimburseLabel.includes('Loading') || !confirm) {
      return;
    }
    setReimburseLabel('Loading...');
    await reimburseTransactions();
    setReimburseLabel('Done');
  };

  return (
    <div className="statistic-block statistic-block-3">
      <div className="statistic-block-content statistic-owning">
        <div className="statistic-owning--title">
          {balanceTotal == 0
            ? 'All good!'
            : balanceTotal < 0
            ? `${UsersStore.friend.firstName} owes you`
            : `You owe ${UsersStore.friend.firstName}`}
        </div>
        <div className="statistic-owning--balance">
          {formatMoney(Math.abs(balanceTotal), 0, 'USD')}
        </div>
        <Button onClick={() => reimburse()} disabled={balanceTotal == 0}>
          <ReimbursedSVG />
          <span>{reimburseLabel}</span>
        </Button>
      </div>
    </div>
  );
}

export default Reimburse;
