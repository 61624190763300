/**
 *  API main
 */
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const myAxios = axios.create({
  baseURL:
    window.location.hostname === 'localhost'
      ? 'http://localhost:5001/'
      : 'https://us-central1-ddbills-5ec60.cloudfunctions.net/firebaseApp',
});

myAxios.interceptors.request.use((config) => {
  const auth = getAuth();
  if (!!auth.currentUser) {
    config.headers.common.DDB_USER_UID = auth.currentUser.uid;
    config.headers.common.DDB_USER_EMAIL = auth.currentUser.email;
  }
  return config;
});

myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace('/auth');
    }
    return Promise.reject(error);
  }
);

export default myAxios;
