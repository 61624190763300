/**
 *  Logout container
 */
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import './style.scss';

const Logout = () => {
  useEffect(async () => {
    const auth = getAuth();
    await auth.signOut();
    window.location.href = '/auth';
  }, []);

  return (
    <div className="page--magic-link">
      <h1 className="center">DDB</h1>
      <p>Signing out...</p>
    </div>
  );
};

export default Logout;
