/**
 *  Transactions MobX Store
 */
import { makeAutoObservable, runInAction, reaction } from 'mobx';
import {
  getFirestore,
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  onSnapshot,
} from 'firebase/firestore';

import { searchTransactions } from '@src/api/transactions';
import Transaction from '@src/models/transaction';

const FIREBASE_COLLECTION_NAME = 'Transaction';
const FIRESTORE_QUERY_ORDER_BY = 'dateTimestamp';

export default class TransactionsStore {
  list = {};
  usersStore;
  loading;
  sortBy = 'date';
  sortDirection = 'desc';

  _onSnapshotUnsubscribes = [];
  _debouncedFn = null;

  FIRESTORE_QUERY_LIMIT = 400;

  constructor(usersStore) {
    makeAutoObservable(this);
    this.usersStore = usersStore;
  }

  /**
   *  Fetch all Transaction
   */
  fetch(options) {
    let db = getFirestore();
    let { type, project, queryLimit } = options;
    queryLimit = queryLimit || this.FIRESTORE_QUERY_LIMIT;

    this.loading = true;

    let argsCurrentUser = [
      collection(db, FIREBASE_COLLECTION_NAME),
      where('userUid', '==', this.usersStore?.currentUser?.uid),
      where('deletedAt', '==', null),
      orderBy(FIRESTORE_QUERY_ORDER_BY, 'desc'),
      limit(queryLimit),
    ];

    let argsFriend = [
      collection(db, FIREBASE_COLLECTION_NAME),
      where('userUid', '==', this.usersStore?.friend?.uid),
      where('deletedAt', '==', null),
      orderBy(FIRESTORE_QUERY_ORDER_BY, 'desc'),
      limit(queryLimit),
    ];

    if (!!project) {
      argsCurrentUser.push(where('project', '==', project));
      argsFriend.push(where('project', '==', project));
    }

    this.unsubscribeOnSnapshotCallback();

    this.list = {};
    const onSnapshotCallback = (transactions) => {
      console.log('onSnapshotCallback');
      runInAction(() => {
        transactions.forEach((d) => {
          let t = new Transaction({
            uid: d.id,
            ...d.data(),
          });
          if (t.shouldBeListed(type)) {
            this.list[d.id] = t;
          } else {
            delete this.list[d.id];
          }
        });
        this.loading = false;
      });
    };

    this._onSnapshotUnsubscribes.push(
      onSnapshot(query(...argsCurrentUser), onSnapshotCallback)
    );
    if (!!this.usersStore?.friend?.uid) {
      this._onSnapshotUnsubscribes.push(
        onSnapshot(query(...argsFriend), onSnapshotCallback)
      );
    }
  }

  unsubscribeOnSnapshotCallback() {
    let unsubscribeFn = null;
    while ((unsubscribeFn = this._onSnapshotUnsubscribes.pop())) {
      unsubscribeFn();
    }
  }

  /**
   *  Search w/ Algolia
   */
  async search(query) {
    console.info('[Store][Transactions][search]', query);
    this.unsubscribeOnSnapshotCallback();

    this.loading = true;
    this.list = {};
    let { data } = await searchTransactions(query);
    runInAction(() => {
      data.forEach((d) => {
        let t = new Transaction({ ...d });
        if (t.shouldBeListed()) {
          this.list[t.uid] = t;
        }
      });
      this.loading = false;
    });
  }

  /**
   *  Get all transactions associated to a project
   */
  async getTransactionsBy(queryOptions) {
    console.info('[Store][Transactions][getMonthlyTransactions]', queryOptions);

    let db = getFirestore();
    let queryArgs = [
      collection(db, FIREBASE_COLLECTION_NAME),
      where('deletedAt', '==', null),
    ];

    for (let option of queryOptions) {
      let [field, operator, value] = option;
      queryArgs.push(where(field, operator, value));
    }

    let transactions = [];
    let querySnapshot = await getDocs(query(...queryArgs));
    querySnapshot.forEach((doc) => {
      let t = new Transaction({
        uid: doc.id,
        ...doc.data(),
      });
      if (t.shouldBeListed()) {
        transactions.push(t);
      }
    });
    return transactions;
  }

  /**
   *  Get last reimbursed transaction
   */
  async getLastReimbursed() {
    console.info('[Store][Transactions][getLastReimbursed]');

    let db = getFirestore();
    let queryArgs = [
      collection(db, FIREBASE_COLLECTION_NAME),
      where('reimbursedAt', '!=', null),
      where('deletedAt', '==', null),
      orderBy('reimbursedAt', 'desc'),
      limit(1),
    ];

    let transactions = [];
    let querySnapshot = await getDocs(query(...queryArgs));
    querySnapshot.forEach((doc) => {
      let t = new Transaction({
        uid: doc.id,
        ...doc.data(),
      });
      transactions.push(t);
    });
    return transactions[0];
  }

  /**
   *  Get LIST length
   */
  getListLength() {
    if (!this.list) {
      return 0;
    }
    return Object.keys(this.list).length;
  }

  setSortBy(sortBy) {
    this.sortBy = sortBy;
  }

  triggerSortDirection() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  }

  /**
   *  Order this.LIST and returns an array of Transactions
   */
  getOrderedList() {
    let array = [];
    for (let key of Object.keys(this.list)) {
      array.push(this.list[key]);
    }

    return array.sort((t1, t2) => {
      if (this.sortBy === 'amount') {
        return this._sortByAmount(t1, t2);
      }
      return this._sortByDate(t1, t2);
    });
  }

  _sortByDate(t1, t2) {
    if (t1.dateYearMonthDay === t2.dateYearMonthDay) {
      if (t1.createdAt > t2.createdAt) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      return this.sortDirection === 'asc' ? 1 : -1;
    }

    if (t1.dateYearMonthDay > t2.dateYearMonthDay) {
      return this.sortDirection === 'asc' ? 1 : -1;
    }
    return this.sortDirection === 'asc' ? -1 : 1;
  }

  _sortByAmount(t1, t2) {
    if (t1.amount() > t2.amount()) {
      return this.sortDirection === 'asc' ? 1 : -1;
    }
    return this.sortDirection === 'asc' ? -1 : 1;
  }
}
