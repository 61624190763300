/**
 *  Format strings utils
 */

import moment from 'moment';

export const formatMoney = (amount, digits, currency) => {
  if (typeof amount === 'undefined' || amount === null) {
    return '';
  }
  if (typeof digits === 'undefined' || digits === null) {
    digits = 2;
  }
  if (typeof currency === 'undefined' || currency === null) {
    currency = 'USD';
  }
  const locale = currency === 'EUR' ? 'fr-FR' : 'en-US';
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return formatter.format(amount);
};

export const formatDate = (date, short) => {
  if (typeof date === 'undefined') {
    return '';
  }

  if (/^\d+$/.test(date)) {
    date = parseFloat(date, 10);
  }

  let format = !!short ? 'MMM. D' : 'MMM D, YYYY';
  return moment(date).local().format(format);
};

export const formatTime = (date) => {
  if (typeof date === 'undefined') {
    return '';
  }

  if (/^\d+$/.test(date)) {
    date = parseFloat(date, 10);
  }
  return moment(date).local().format('MMM D, ha');
};

export const formatMonth = (date) => {
  if (typeof date === 'undefined') {
    return '';
  }

  return moment(date).local().format('MMMM YYYY');
};
