/**
 *  Internal Plaid API
 */

import myAxios from './index';

export const fetchPlaidAccounts = () => myAxios.get(`/plaid/accounts`);
export const deletePlaidItem = (accessToken) =>
  myAxios.delete(`/plaid/delete_item/${accessToken}`);
export const fetchPlaidTransactions = () => myAxios.get(`/plaid/transactions`);

export const createLinkToken = (data) =>
  myAxios.post(`/plaid/create_link_token`, data);
export const exchange = (data) => myAxios.post(`/plaid/exchange`, data);
