/**
 *  FlyingMenu component
 */
import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import useKeyPress from '@src/utils/useKeyPress.hook';

import './style.scss';

const FlyingMenu = ({
  onClose,
  onEnter,
  search,
  elements,
  title,
  children,
  className = '',
}) => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [focusedElement, setFocusedElement] = useState(0);
  let searchInputRef = useRef(null);
  let flyingModalRef = useRef(null);

  useKeyPress(() => triggerOnClose(), 'Escape');
  useKeyPress(() => {
    setFocusedElement((prev) =>
      prev - 1 < 0 ? elements.length - 1 : prev - 1
    );
  }, 'ArrowUp');

  useKeyPress(() => {
    setFocusedElement((prev) => (prev + 1 >= elements.length ? 0 : prev + 1));
  }, 'ArrowDown');

  useKeyPress(() => triggerOnEnter(focusedElement), 'Enter');

  useEffect(() => {
    positionFlyingModal();
    searchInputRef?.current?.focus();
  }, []);

  const renderSearch = () => {
    if (!search) {
      return null;
    }

    return (
      <input
        type="search"
        ref={searchInputRef}
        placeholder={title ? title : 'Search'}
        className="noBorderRadius big"
        onChange={(e) => triggerSearch(e.target.value)}
      />
    );
  };

  const positionFlyingModal = () => {
    if (!flyingModalRef?.current) {
      return;
    }
    let { width, bottom, right } =
      flyingModalRef?.current.getBoundingClientRect();
    if (bottom > window.innerHeight + window.scrollY) {
      flyingModalRef?.current.classList.add('bottom');
    }
    if (right + width / 2 > window.innerWidth + window.scrollX) {
      flyingModalRef?.current.classList.add('right');
    }
  };

  const triggerSearch = (query) => {
    setFocusedElement(0);
    setSearchQuery(query);
  };

  const triggerOnClose = useCallback(() => {
    !!onClose && onClose();
  }, [onClose]);

  const triggerOnEnter = useCallback(
    (i) => {
      let filteredElements = getFilteredElements();
      if (0 <= i && i < filteredElements.length) {
        !!onEnter && onEnter(filteredElements[i]);
      }
      triggerOnClose();
    },
    [onEnter, searchQuery]
  );

  const getFilteredElements = () => {
    if (!searchQuery) {
      return elements;
    }
    let query = (searchQuery || '').toLowerCase();
    return elements.filter((elm) =>
      elm.toString().toLowerCase().includes(query)
    );
  };

  /**
   *  Render <li>s
   */
  const renderList = () => {
    if (!!children) {
      return children;
    }

    let filteredElements = getFilteredElements();
    if (filteredElements.length === 0) {
      return (
        <li>
          <small>Nothing found</small>
        </li>
      );
    }
    return filteredElements.map((elm, i) => (
      <li
        key={i}
        className={classnames({
          focused: focusedElement === i,
        })}
        onClick={() => triggerOnEnter(i)}
      >
        {elm.toString() || elm}
      </li>
    ));
  };

  return (
    <div className="flying-menu">
      <div className="modal-background" onClick={triggerOnClose} />
      <div
        className={`flying-menu--block non-selectable ${className}`}
        ref={flyingModalRef}
      >
        {renderSearch()}
        <ul>{renderList()}</ul>
      </div>
    </div>
  );
};

export default observer(FlyingMenu);
