/**
 *  Firebase utils
 */

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyDhNUXUolfGukNG2HC0x_aSBZ-ikxNg1Y8',
  authDomain: 'ddbills-5ec60.firebaseapp.com',
  projectId: 'ddbills-5ec60',
  storageBucket: 'ddbills-5ec60.appspot.com',
  messagingSenderId: '402230821887',
  appId: '1:402230821887:web:1cfc61a225aecaba6addca',
};

// Initialize Firebase
export default initializeApp(config);

export const firestore = getFirestore();
