/**
 *  Accounts Statistics container
 */
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { observer } from 'mobx-react';

import { useStores } from '@src/store';
import Graph from './components/graph';

import './style.scss';
import TimeframeSelector from './components/timeframeSelector';

const Accounts = observer(() => {
  const { BankAccountStatusStores } = useStores();
  const [fromDate, setFromDate] = useState(
    moment().startOf('month').format('YYYY-MM-DD')
  );

  useEffect(() => {
    BankAccountStatusStores.fetch({ fromDate });
  }, [fromDate]);

  const getBankAccounts = () => {
    if (BankAccountStatusStores.list.length === 0) {
      return;
    }
    let bankAccounts = {};
    BankAccountStatusStores.list.map((account) => {
      if (!bankAccounts.hasOwnProperty(account.accountId)) {
        bankAccounts[account.accountId] = {};
      }
      let date = moment(account.createdAt).format('YYYY-MM-DD');
      bankAccounts[account.accountId][date] = account;
    });
    return bankAccounts;
  };

  const renderGraphs = () => {
    let bankAccounts = getBankAccounts();
    if (!bankAccounts) {
      return;
    }
    return (
      <div className="accounts-graphs">
        {Object.keys(bankAccounts)
          .sort((id1, id2) => {
            let key1 = Object.keys(bankAccounts[id1])[0];
            let key2 = Object.keys(bankAccounts[id2])[0];

            let institutionName1 = bankAccounts[id1][key1].institution.name;
            let institutionName2 = bankAccounts[id2][key2].institution.name;

            let type1 = bankAccounts[id1][key1].type;
            let type2 = bankAccounts[id2][key2].type;

            if (institutionName1 < institutionName2) return -1;
            if (institutionName1 > institutionName2) return 1;
            return type1 > type2 ? -1 : 1;
          })
          .map((accountId, i) => (
            <Graph accountDates={bankAccounts[accountId]} key={i} />
          ))}
      </div>
    );
  };

  return (
    <div className="app-page accounts">
      <h1 className="df-sb">
        <span>Bank accounts</span>
        <TimeframeSelector fromDate={fromDate} setFromDate={setFromDate} />
      </h1>
      <hr />

      {renderGraphs()}
    </div>
  );
});

export default Accounts;
