/**
 *  Month Statistics
 */
import moment from 'moment';
import { Link } from 'react-router-dom';

import JaugedCategories from '../../../components/JaugedCategories';
import { formatMoney } from '../../../utils/format';

function MonthStatistic({ monthlyStatistic, dateYearMonth, topX }) {
  if (!monthlyStatistic) {
    return (
      <div className="statistic-block statistic-block-3" key={dateYearMonth}>
        <div className="statistic-block-content">
          <div className="statistic-block--title">
            {moment(dateYearMonth).format('MMMM YYYY')}
          </div>
          Loading...
        </div>
      </div>
    );
  }

  let categories = {};
  let categoriesShared = {};
  let exceptionalCategory = 0;
  let magicCategory = 0;
  for (let transaction of monthlyStatistic) {
    let amount = transaction.amountPaidByCurrentUser();
    if (amount === 0) {
      continue;
    }

    if (transaction.isExceptional()) {
      exceptionalCategory += amount;
      continue;
    }
    if (transaction.isMagic()) {
      magicCategory += amount;
      continue;
    }

    let category = transaction.category;
    if (transaction.shared()) {
      categoriesShared[category] =
        (categoriesShared[category] || 0) + transaction.amount();
    } else {
      categories[category] = (categories[category] || 0) + amount;
    }
  }

  return (
    <div className="statistic-block statistic-block-3" key={dateYearMonth}>
      <div className="statistic-block-content">
        <Link
          to="/"
          className="statistic-block--title"
          state={{
            filter: moment(dateYearMonth).format('YYYY-MM'),
          }}
        >
          {moment(dateYearMonth).format('MMMM YYYY')}
        </Link>

        {/* Perso */}
        <JaugedCategories
          title="My expenses"
          topX={topX}
          categories={categories}
          dateYearMonth={dateYearMonth}
        />

        {/* Joint Account */}
        <JaugedCategories
          title="Shared expenses"
          topX={topX}
          hr
          shared
          categories={categoriesShared}
          dateYearMonth={dateYearMonth}
        />

        <ul>
          {/* Exceptional */}
          <li>
            {!!exceptionalCategory ? (
              <Link
                to="/"
                state={{
                  filter: `[exceptional=1] ${dateYearMonth}`,
                }}
              >
                <hr />
                <div className="statistic-block--category exceptional">
                  ✨ Exceptional expenses
                </div>
                <div
                  className="statistic-block--jauge"
                  style={{ '--customColor': '#99abff' }}
                >
                  <div className="statistic-block--jauge-label">
                    {formatMoney(exceptionalCategory, 0, 'USD')}
                  </div>
                </div>
              </Link>
            ) : null}
          </li>
          {/* Magic */}
          <li>
            {!!magicCategory ? (
              <Link
                to="/"
                state={{
                  filter: `[magic=1] ${dateYearMonth}`,
                }}
              >
                <hr />
                <div className="statistic-block--category magic">
                  🧞‍♂️ Carte magique expenses
                </div>
                <div
                  className="statistic-block--jauge"
                  style={{ '--customColor': '#ff8888' }}
                >
                  <div className="statistic-block--jauge-label">
                    {formatMoney(magicCategory, 0, 'USD')}
                  </div>
                </div>
              </Link>
            ) : null}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MonthStatistic;
