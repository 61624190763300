/**
 *  Graph component
 */
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
} from 'recharts';

import { formatMoney, formatDate } from '@src/utils/format';

function Graph({ accountDates }) {
  let graphData = [];
  let isCredit = false;

  Object.keys(accountDates).map((date) => {
    let accountDate = accountDates[date];
    let amount = Math.round(accountDate.balances.current);
    isCredit = accountDate.type === 'credit';

    if (isCredit) {
      amount *= -1;
    }

    let obj = {
      amount,
      name: date,
      color: accountDate.institution.primaryColor,
      accountName: `${accountDate.institution.name} – ${accountDate.name}`,
    };
    if (!!accountDate.investedAmount) {
      obj['invested amount'] = accountDate.investedAmount;
    }
    graphData.push(obj);
  });

  if (graphData.length === 0) {
    return;
  }

  const renderBenefits = () => {
    if (
      graphData.length === 0 ||
      !graphData[graphData.length - 1]['invested amount']
    ) {
      return;
    }
    let lastAmount = graphData[graphData.length - 1].amount;
    let lastInvestedAmount = graphData[graphData.length - 1]['invested amount'];

    return (
      <div className="accounts-graphs--benefits">
        {lastAmount > lastInvestedAmount ? 'Benefits: ' : 'Losses: '}
        {formatMoney(lastAmount - lastInvestedAmount, 0)}
      </div>
    );
  };

  return (
    <div className="accounts-graphs--graph">
      <h3>{graphData[0].accountName}</h3>

      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={graphData}>
          <XAxis
            dataKey="name"
            interval="preserveStartEnd"
            axisLine={{ stroke: '#a2aab3' }}
            tickLine={false}
            tickFormatter={(value) => formatDate(value, true)}
            orientation={isCredit ? 'top' : 'bottom'}
          />
          <YAxis
            domain={[
              (dataMin) => Math.min(0, dataMin * 1.2),
              (dataMax) => Math.max(0, dataMax * 1.2),
            ]}
            interval="preserveEnd"
            tickFormatter={(value) => formatMoney(value, 0, 'USD')}
            axisLine={{ stroke: '#a2aab3' }}
            tickLine={{ stroke: '#a2aab3' }}
          />
          <Tooltip formatter={(value) => formatMoney(value, 0, 'USD')} />
          <Area
            type="monotone"
            dataKey="amount"
            stroke={graphData[0].color}
            fill={graphData[0].color}
            fillOpacity={0.05}
          />
          <Area
            type="monotone"
            dataKey="invested amount"
            stroke="#aaa"
            fillOpacity={0}
          />
        </AreaChart>
      </ResponsiveContainer>

      {renderBenefits()}
    </div>
  );
}

export default Graph;
