/**
 *  User Model
 */
import { makeAutoObservable, runInAction } from 'mobx';
import { getAuth } from 'firebase/auth';
import { getFirestore, setDoc, doc } from 'firebase/firestore';

import { fetchPlaidAccounts } from '@src/api/plaid';

export const OPPOSITE_VIEW = false;
const FIRESTORE_DOCUMENT_NAME = 'User';

export default class User {
  uid;
  firstName;
  lastName;
  email;
  photoURL;
  plaidAccounts;
  investedAmounts;

  constructor(dbObj) {
    makeAutoObservable(this);
    this.uid = dbObj.uid || null;
    this.firstName = dbObj.firstName || null;
    this.lastName = dbObj.lastName || null;
    this.email = dbObj.email || null;
    this.photoURL = dbObj.photoURL || null;
    this.plaidAccounts = dbObj.plaidAccounts || null;
    this.investedAmounts = dbObj.investedAmounts || null;
  }

  /**
   *  Get Teammate's short name
   */
  shortName() {
    if (!!this.firstName) {
      return `${this.firstName}`.trim();
    }
    return this.email;
  }

  /**
   *  Get Teammate's short name
   */
  shortNameOrYou() {
    if (!!this.isCurrentUser()) {
      return 'You';
    }
    if (!!this.firstName) {
      return `${this.firstName}`.trim();
    }
    return this.email;
  }

  /**
   *  Get Teammate's full name
   */
  fullName() {
    if (!!this.firstName || !!this.lastName) {
      return `${this.firstName} ${this.lastName}`.trim();
    }
    return this.email;
  }

  isCurrentUser() {
    const auth = getAuth();
    return !OPPOSITE_VIEW
      ? auth.currentUser?.uid === this.uid
      : auth.currentUser?.uid !== this.uid;
  }

  async fetchPlaidAccounts() {
    const { data } = await fetchPlaidAccounts();
    runInAction(() => {
      this.plaidAccounts = data;
    });
  }

  /**
   * ACTIONS
   */
  async updateInvestedAmount(accountId, newValue) {
    let db = getFirestore();
    this.investedAmounts = this.investedAmounts || {};
    this.investedAmounts[accountId] = newValue;
    await setDoc(
      doc(db, FIRESTORE_DOCUMENT_NAME, this.uid),
      { investedAmounts: this.investedAmounts },
      { merge: true }
    );
  }

  getInvestedAmount(accountId) {
    return this.investedAmounts?.hasOwnProperty(accountId)
      ? this.investedAmounts[accountId]
      : '';
  }
}
