/**
 *  NavigationMenu container
 */
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { useStores } from '@src/store';
import InboxSVG from '@src/components/Icons/inbox.svg';
import ActivitySVG from '@src/components/Icons/activity.svg';
import SettingsSVG from '@src/components/Icons/settings.svg';
import BarChartSVG from '@src/components/Icons/bar-chart.svg';

import './style.scss';

const NavigationMenu = () => {
  const { UsersStore } = useStores();

  return (
    <nav className="navigation-menu non-selectable">
      <div className="navigation-menu--content">
        <NavLink
          to="/"
          className={({ isActive }) => classnames({ active: isActive })}
          state={{
            project: `Inbox`,
          }}
        >
          <InboxSVG />
          <span className="navlink-text">Transactions</span>
        </NavLink>
        <NavLink
          to="/statistics"
          className={({ isActive }) => classnames({ active: isActive })}
        >
          <ActivitySVG />
          <span className="navlink-text">Statistics</span>
        </NavLink>
        <NavLink
          to="/accounts"
          className={({ isActive }) => classnames({ active: isActive })}
        >
          <BarChartSVG />
          <span className="navlink-text">Accounts</span>
        </NavLink>
        <NavLink
          to="/settings"
          className={({ isActive }) => classnames({ active: isActive })}
        >
          <SettingsSVG />
          <span className="navlink-text">Settings</span>
        </NavLink>
      </div>

      <div className="navigation-menu--footer">
        <img
          src={UsersStore.currentUser.photoURL}
          referrerPolicy="no-referrer"
        />
        <div className="navigation-menu--footer-name">
          <span className="navlink-text">
            {UsersStore.currentUser.shortName()}
          </span>
        </div>
      </div>
    </nav>
  );
};

export default NavigationMenu;
