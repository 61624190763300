/**
 *  Timeframe Selector
 */
import moment from 'moment';

function TimeframeSelector({ fromDate, setFromDate }) {
  return (
    <select
      value={fromDate}
      onChange={(event) => setFromDate(event.target.value)}
    >
      <option value={moment().startOf('month').format('YYYY-MM-DD')}>
        Current month
      </option>
      <option
        value={moment()
          .subtract(3, 'months')
          .startOf('month')
          .format('YYYY-MM-DD')}
      >
        Last 3 months
      </option>
      <option
        value={moment()
          .subtract(6, 'months')
          .startOf('month')
          .format('YYYY-MM-DD')}
      >
        Last 6 months
      </option>
      <option
        value={moment()
          .subtract(12, 'months')
          .startOf('month')
          .format('YYYY-MM-DD')}
      >
        Last 12 months
      </option>
      <option value={moment().startOf('year').format('YYYY-MM-DD')}>YTD</option>
    </select>
  );
}

export default TimeframeSelector;
