/**
 *  Users MobX Store
 */
import { makeAutoObservable, runInAction, reaction } from 'mobx';
import { observer } from 'mobx-react';
import {
  getFirestore,
  query,
  collection,
  where,
  onSnapshot,
  setDoc,
  doc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import User, { OPPOSITE_VIEW } from '@src/models/user';

const FIREBASE_COLLECTION_NAME = 'User';

export default class UsersStores {
  list = [];
  loggedUserUid;
  currentUser = {};
  friend = {};

  AUTHORIZED_EMAILS = ['alice.default@gmail.com', 'xave.durand@gmail.com'];

  constructor() {
    makeAutoObservable(this);
    this.reactions();
  }

  reactions() {
    reaction(
      () => this.loggedUserUid,
      () => this.fetch()
    );
  }

  fetch() {
    console.info('[Store][UsersStores][fetch]');
    let db = getFirestore();
    let q = query(collection(db, FIREBASE_COLLECTION_NAME));
    onSnapshot(q, (users) => {
      runInAction(() => {
        this.list = [];
        users.forEach((d) => {
          let user = new User({
            uid: d.id,
            ...d.data(),
          });
          this.list.push(user);
          if (d.id === this.loggedUserUid) {
            !OPPOSITE_VIEW ? (this.currentUser = user) : (this.friend = user);
          } else {
            !OPPOSITE_VIEW ? (this.friend = user) : (this.currentUser = user);
          }
        });
      });
    });
  }

  getUserByUid(uid) {
    return this.list.find((u) => u.uid === uid) || {};
  }

  isEmailAuthorized(registeredUser) {
    return this.AUTHORIZED_EMAILS.includes(registeredUser.email);
  }

  /**
   *  Create User in Firestore
   */
  async afterSignIn(registeredUser) {
    let db = getFirestore();
    let [firstName, lastName] = registeredUser.displayName.split(' ');
    await setDoc(
      doc(db, FIREBASE_COLLECTION_NAME, registeredUser.uid),
      {
        firstName,
        lastName,
        email: registeredUser.email,
        photoURL: registeredUser.photoURL,
      },
      { merge: true }
    );
  }

  /**
   *  Check if user is logged in in Firebase Auth
   */
  listenStateChanged() {
    const auth = getAuth();
    let user = onAuthStateChanged(auth, (user) => {
      runInAction(() => {
        if (user) {
          this.loggedUserUid = user.uid;
        } else {
          window.location.href = '/auth';
        }
      });
    });
  }
}
