/**
 *  Login container
 */
import { useEffect, useState } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import { useStores } from '@src/store';
import Button from '@src/components/Button';

import './style.scss';

const Login = () => {
  const [message, setMessage] = useState('');
  const [provider, setProvider] = useState('');
  const { UsersStore } = useStores();

  useEffect(() => {
    setProvider(new GoogleAuthProvider());
  }, []);

  const triggerAuthentication = () => {
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        if (!UsersStore.isEmailAuthorized(result.user)) {
          setMessage(`This email is unauthorized (${result.user.email})`);
          auth.signOut();
          return;
        }
        await UsersStore.afterSignIn(result.user);
        window.location.href = '/';
      })
      .catch((error) => {
        console.warn(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        setMessage(`[${errorCode}] ${errorMessage}`);
      });
  };

  return (
    <div className="page--login">
      <div>
        <h1 className="center">Log in to DDB</h1>
        <p>{message}</p>
        <Button
          type="submit"
          text="Sign in with Google"
          onClick={triggerAuthentication}
        />
      </div>
    </div>
  );
};

export default Login;
