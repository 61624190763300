/**
 *  Project component
 */
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { useStores } from '@src/store';
import Category from '@src/models/category';
import MoreSVG from '@src/components/Icons/more-horizontal.svg';
import SharedSVG from '@src/components/Icons/shared.svg';
import { formatMoney } from '@src/utils/format';

function Project({ project, projectsStatistics }) {
  const { UsersStore } = useStores();
  const [categoriesOpen, setCategoriesOpen] = useState(false);

  if (!projectsStatistics || !projectsStatistics[project]) {
    return;
  }

  let details = projectsStatistics[project];

  return (
    <div className="statistic-block statistic-block-4" key={project}>
      <div className="statistic-block-content">
        <Link
          to="/"
          state={{ project: details.name }}
          className="statistic-block--title"
        >
          <div>{project}</div>
          <MoreSVG />
        </Link>

        {/* Projects - You */}
        <div className="statistic-block--sum">
          <div className="statistic-block--label">
            <img
              src={UsersStore.currentUser.photoURL}
              referrerPolicy="no-referrer"
            />
            {UsersStore.currentUser.shortNameOrYou()}
          </div>
          <div
            className={classnames('sum', {
              green: details.currentUser < -1,
            })}
          >
            {details.currentUser < 0 ? '+' : null}
            {formatMoney(Math.abs(details.currentUser), 0, 'USD')}
          </div>
        </div>

        {/* Projects - Friend */}
        <div className="statistic-block--sum">
          <div className="statistic-block--label">
            <img
              src={UsersStore.friend.photoURL}
              referrerPolicy="no-referrer"
            />
            {UsersStore.friend.shortNameOrYou()}
          </div>
          <div className={classnames('sum', { green: details.friend < -1 })}>
            {details.friend < 0 ? '+' : null}
            {formatMoney(Math.abs(details.friend), 0, 'USD')}
          </div>
        </div>

        {/* Projects - Shared */}
        <div className="statistic-block--sum">
          <div className="statistic-block--label">
            <SharedSVG />
            Shared
          </div>
          <div className={classnames('sum', { green: details.shared < -1 })}>
            {details.shared < 0 ? '+' : null}
            {formatMoney(Math.abs(details.shared), 0, 'USD')}
          </div>
        </div>

        {/* Projects - Exceptional */}
        {details.exceptional !== 0 ? (
          <div className="statistic-block--sum small">
            <div className="statistic-block--label statistic-block--label-exceptional exceptional">
              ✨ Exceptional
            </div>
            <div
              className={classnames('sum', 'exceptional', {
                green: details.exceptional < -1,
              })}
            >
              {details.exceptional < 0 ? '+' : null}
              {formatMoney(Math.abs(details.exceptional), 0, 'USD')}
            </div>
          </div>
        ) : null}

        {/* Projects - Categories */}
        <div
          className="statistic-block--hidden-title non-selectable"
          onClick={() => setCategoriesOpen(!categoriesOpen)}
        >
          <span>{!categoriesOpen ? '▸' : '▾'}</span>
          Categories
        </div>
        <div
          className={classnames('statistic-block--hidden', {
            open: !!categoriesOpen,
          })}
        >
          {Object.keys(details.categories)
            .sort((k1, k2) =>
              details.categories[k1] > details.categories[k2] ? -1 : 1
            )
            .map((key) => {
              let category = new Category(key);
              return (
                <div className="statistic-block--sum small" key={key}>
                  <Link
                    to="/"
                    className="statistic-block--label statistic-block--label-category"
                    state={{
                      filter: `[project:"${details.name}"][category:"${key}"]`,
                    }}
                  >
                    {category.toString()}
                  </Link>
                  <div
                    className={classnames('sum', {
                      green: details.categories[key] < -1,
                    })}
                  >
                    {details.categories[key] < 0 ? '+' : null}
                    {formatMoney(Math.abs(details.categories[key]), 0, 'USD')}
                  </div>
                </div>
              );
            })}
        </div>

        {/* Projects - Total */}
        <div className="statistic-block--sum">
          <div className="statistic-block--label statistic-block--label-total">
            Total
          </div>
          <div className={classnames('sum', { green: details.total < -1 })}>
            {details.total < 0 ? '+' : null}
            {formatMoney(Math.abs(details.total), 0, 'USD')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project;
