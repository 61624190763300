import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';

import Category from '@src/models/category';
import { formatMoney } from '@src/utils/format';
import MoreSVG from '@src/components/Icons/more-horizontal.svg';

import './style.scss';

const JaugedCategories = ({
  title,
  categories,
  dateYearMonth,
  topX,
  hr,
  shared,
}) => {
  let categoriesArray = Object.keys(categories)
    .map((key) => [new Category(key), categories[key]])
    .sort((c1, c2) => (c1[1] < c2[1] ? 1 : -1))
    .slice(0, topX);

  if (categoriesArray.length === 0) {
    return (
      <p>
        <i>{`No transaction made in ${moment(dateYearMonth).format(
          'MMMM'
        )}.`}</i>
      </p>
    );
  }

  return (
    <div className="jauged-category">
      {!!hr ? <hr /> : null}
      <div className="df-sb df-aic">
        <Link
          to="/"
          className="jauged-category--title"
          state={{
            filter: `${shared ? '[shared=1]' : '[shared=0]'} ${dateYearMonth}`,
          }}
        >
          {title}
        </Link>
        <div className="jauged-category--total">
          {formatMoney(
            Object.keys(categories)
              .map((key) => [new Category(key), categories[key]])
              .reduce((sum, cat) => sum + cat[1], 0),
            0,
            'USD'
          )}
        </div>
      </div>
      <ul>
        {categoriesArray.map((cat) => (
          <li key={cat[0]}>
            <Link
              to="/"
              className={classnames({ negative: cat[1] < 0 })}
              state={{
                filter: `[category:"${cat[0].name}"]${
                  shared ? '[shared=1]' : '[shared=0]'
                } ${dateYearMonth}`,
              }}
            >
              <div className="statistic-block--category">
                {cat[0].toString()}
                <MoreSVG />
              </div>
              <div
                className="statistic-block--jauge"
                style={{
                  width: `${(cat[1] * 100) / categoriesArray[0][1]}%`,
                  '--customColor': cat[0].color(),
                }}
              >
                <div
                  className={classnames({
                    'statistic-block--jauge-label': true,
                    right:
                      (cat[1] * 100) / categoriesArray[0][1] < 70 && cat[1] >= 0,
                  })}
                >
                  {formatMoney(cat[1], 0, 'USD')}
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default JaugedCategories;
